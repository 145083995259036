import React, { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "./Redux/app/hooks";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import MainDashboard from "./pages/MainDashboard";
import NavBar from "./container/NavBar/NavBar";
import ProtectedRoute from "./Auth/ProtectedRoutes";
import SSOLogin from "./pages/SSOLogin";
import themesConfig from "./utils/themesConfig";
import NotFound from "./pages/NotFound";
// import socket, { useSocket } from "./socket";

import { useOnClickOutside } from "./CustomHooks/useOnClickOutside";
import { getGirdLayout, getUserGridData, getUserPanelsData } from "./Redux/features/MainDashboard/MainDashBoardSlice";
import { update_prices } from "./Redux/features/ChartData/ChartDataSlice";
import { updateBuyBtnDisabled, updateSellBtnDisabled } from "./Redux/features/OrderSection/OrderSectionSlice";
import socket from "./socket";
import { abort } from "process";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const baseURL: any = themesConfig.get_react_app_login_url();
function App() {
  const is_socket_connected: any = useAppSelector((state) => state.dashBoard.is_socket_connected);
  const equityChartDataChannel = "equity_chart_bidask_data_channel_" + localStorage.getItem("login-id");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserGridData());
    setTimeout(() => {
      dispatch(getGirdLayout());
    }, 500);

    // dispatch(getUserPanelsData());
    return () => {
      // Clean up the event listener when the component unmounts.
    };
  }, []);
  // useSocket();
  const [show, setShow] = useState(false);
  const ref: any = useRef(null);
  const userId = useAppSelector((state: any) => state.dashBoard.profile.id);

  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <div
          className={`root 
        ${themesConfig.getTheme().theme_class} 
        theme box-border`}
        >
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NavBar />
          <Routes>
            {/* <Route path="/" element={<ProtectedRoute>{socket && <Dashboard />}</ProtectedRoute>} /> */}
            <Route path="/" element={<ProtectedRoute>{socket && <MainDashboard />}</ProtectedRoute>} />
            <Route path="/sso-login" element={<SSOLogin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <div id="modal-root" className="z-50"></div>
          {/* {is_socket_connected === 1 && (
          <div id="warning-connectionBar">
            <p id="warning-bar">Connecting... </p>
          </div>
        )}
        {is_socket_connected === 2 && (
          <div id="connected-connectionBar">
            <p id="connected-bar">Connected </p>
          </div>
        )} */}
          {/* {userId === 2 ? (
          <div className="adminActions" ref={ref}>
            <input type="checkbox" name="adminToggle" className="adminToggle" />
            <a className={`adminButton ${show === true ? "open" : ""}`} href="javascript:void(0);" onClick={() => setShow((show) => !show)}>
              <span>
                Trade
                <br /> Entry
              </span>

              <svg className="adminButtonClose" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
                <path fill="#001a34" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)" />
                <path fill="#001a34" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)" />
              </svg>
            </a>
            <div className="adminButtons">
              <a href={`${baseURL}cash-management/create/transfer`} className={`cash-transfer ${show === true ? "open" : ""}`}>
                <span>CASH</span>
              </a>
              <a href={`${baseURL}list-security-transfers/create`} className={`security-transfer ${show === true ? "open" : ""}`}>
                <span>FOP</span>
              </a>
              <a href={`${baseURL}trades/matched-entry`} className={`matched-entry ${show === true ? "open" : ""}`}>
                <span>B2B</span>
              </a>
              <a href={`${baseURL}trades/trade-entry`} className={`trade-entry ${show === true ? "open" : ""}`}>
                <span>DVP</span>{" "}
              </a>
            </div>
            <div className={`button-overlay ${show === true ? "open" : ""}`} onClick={() => setShow((show) => !show)}></div>
          </div>
         ): null}  */}
        </div>
        <div id="modal-root"></div>

      </BrowserRouter>
    </DndProvider>
  );
}

export default App;
