import { useState, useMemo } from "react";
import classNames from "classnames";
import OrderSearch from "../../../assets/search.svg";
import { useAppSelector } from "../../../Redux/app/hooks";

type Props = {
  exchanges: any[];
  setConValue: any;
  onClose: any;
};

const OptionsForm = ({ exchanges, setConValue, onClose }: Props) => {
  const [searchText, setSearchText] = useState("");
const errMSG = useAppSelector((state)=> state.dashBoard.fut_opt_errMsg)
  const filteredExchanges = useMemo(() => {
    return exchanges.filter((combo) =>
      combo.DisplaySecurity?.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, exchanges]);

  return (
    <div className="overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex relative border rounded-xl">
          <img className="order-search h-3 m-auto pl-4" src={OrderSearch} alt="" />
          <input
            type="search"
            className="w-full placeholder:text-sm rounded-xl px-2 py-1 outline-none"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="overflow-x-auto max-h-44 order-search-list">
          <ul>
            {filteredExchanges.map((combo, index) => (
              <li
                key={combo.DisplaySecurity}
                onClick={() => {
                  setConValue(combo);
                  onClose();
                }}
                className={classNames("border hover:bg-[#B0CCF352]", {
                  "bg-[#f0f0f0]": index % 2 !== 0,
                })}
              >
                <span className="list-type equity-type"></span>
                <div className="text-[0.75rem] leading-[1.25] text-[#a4a3a3] px-[0.6vw] py-[0.42vw] flex justify-start items-start cursor-pointer">
                  {combo.DisplaySecurity}
                </div>
              </li>
            ))}
          </ul>
          {filteredExchanges.length === 0 && (
            <div className="text-center text-sm text-gray-500">No Option securities found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OptionsForm;
