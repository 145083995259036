import React from "react";
import classNames from "classnames";
import { update_form_data } from "../../../Redux/features/OrderSection/OrderSectionSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
type Props = {
  contracts: any[];
  setConValue: any;
  onClose: any;
};

const FutureForm = ({ contracts, setConValue, onClose }: Props) => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector(state => state.orderSection.form_data)
  const errMSG = useAppSelector((state)=> state.dashBoard.fut_opt_errMsg)
  return (
    <div>
      {" "}
      <div className="flex flex-col gap-4">
        <div className="overflow-x-auto order-search-list">
          {contracts?.map((contract, index) => (
            <ul>
              <li
                key={contract.DisplaySecurity}
                onClick={() => {
                  setConValue(contract);
                  dispatch(update_form_data({ vendor: 2 }));
                  onClose();
                }}
                className={classNames("border hover:bg-[#B0CCF352]", { "bg-[#f0f0f0]": index % 2 !== 0 })}
              >
                <span className="list-type equity-type"></span>
                <div className="text-[0.75rem] leading-[1.25] text-[#a4a3a3]  px-[0.6vw] py-[0.42vw] flex justify-start items-start cursor-pointer">
                  {" "}
                  {`${contract?.DisplaySecurity}`}
                </div>
              </li>
            </ul>
          ))} 
          {contracts?.length === 0 && <div className="text-center">No Future securities found.</div>}
          {/* {errMSG} */}
        </div>
      </div>
    </div>
  );
};

export default FutureForm;
