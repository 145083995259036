import React, { useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";
import Svg from "../Svg/Svg";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  text: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, text }) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") onClose();
    };

    if (isOpen) document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50  flex items-center justify-center">
      <div className="bg-white  rounded-lg shadow-xl p-6 min-w-[400px] max-w-[700px] max-h-[600px] overflow-auto">
        <div className="flex  justify-between items-center">
          <h2 className="text-xl w-[400px] overflow-hidden text-ellipsis block whitespace-nowrap  font-bold mb-4">{text}</h2>
          <button onClick={onClose} className="mb-4 text-gray-600 hover:text-gray-900 transition">
            <Svg type="close" />
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default Modal;
