import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import GridItemContainer from "../container/Grid/GridItemContainer";
import PropTypes from "prop-types";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { toast } from "react-toastify";
import {
  update_breakpoints,
  update_main_user_grid,
  addLayoutToUserLayouts,
  updateLayoutInUserLayouts,
  updateUserLayoutPosition,
  updateISUserLayouts,
  storeLayoutToUserLayouts,
  updatesetFilterPanel,
  updatesetSideDrawer,
  updatesetSelectedID,
  updatePriceData,
  updateScreenLock,
  updateBidPriceData,
  updateAskPriceData,
  updatesendNewSecurityRequestStatus,
} from "../Redux/features/MainDashboard/MainDashBoardSlice";
import DashboardTab from "../components/DashboardTab/DashboardTab";

import PopModal from "../components/PopModal/PopModal";
import Filterpanel from "../components/OrderFilterPanel/Filterpanel";
import {
  fetchDefaultLoadData,
  fetchProfile,
  fetchProfileCounterparties,
  fetchTableHiddenFields,
  setFUTOPTData,
  setFUTOPTErrorMsg,
  update_is_socket_connected,
} from "../Redux/features/Dashboard/DashBoardSlice";
import { fetchSettingsData } from "../Redux/features/Settings/SettingSlice";
import { fetchNotifications } from "../Redux/features/NotificationSlice/NotificationSlice";
import SideDrawer from "../components/SideDrawer/SideDrawer";
import Loading from "../components/Loading/Loading";
import TransactionDetails from "../components/TransactionDetails/TransactionDetails";
import Wishlist from "../components/Wishlist/Wishlist";
import AddToWatchList from "../components/Wishlist/AddToWatchList";
import SearchAddToWatchList from "../components/Wishlist/SearchAddToWatchList";
import ManageOrderColumn from "../components/OrderColumn/ManageOrderColumn";
import socket from "../socket";

import {
  addNewSecurityRequest,
  postHoldingTypeChartData,
  update_ask_prices,
  update_bid_prices,
  update_holding_chart_type_option,
  update_prices,
  update_security_message,
  update_send_new_sec_req,
  stream_last_isin,
  stream_last_price,
} from "../Redux/features/ChartData/ChartDataSlice";
import {
  getVendorList,
  updateBuyBtnDisabled,
  updateSellBtnDisabled,
  update_form_data,
  update_rfs_stream_channel_data,
  update_fx_rfq_quote,
  update_tradeweb_quotes_stream_channel_data,
  update_client_markup_data,
  requestRfq,
} from "../Redux/features/OrderSection/OrderSectionSlice";
import { useIsMount } from "../CustomHooks/useIsMount ";
import "../index.scss";
import SecurityAddRequest from "../components/AutoCompleteSearchBox/SecurityAddRequest";
import Menubar from "../components/Menubar/Menubar";
// import socket from "../../../socket";
// { layouts }: any
const ResponsiveGridLayout = WidthProvider(Responsive);
function MainDashboard(props: any) {
  // console.log("propssMain", props);
  const dispatch = useAppDispatch();
  const girdLayout = useAppSelector((state) => state.mainDashBoard.girdLayout);
  const layouts = useAppSelector((state) => state.mainDashBoard.user_layouts);
  const is_user_layouts = useAppSelector((state) => state.mainDashBoard.is_user_layouts);
  const breakpoints = useAppSelector((state) => state.mainDashBoard.breakpoints);
  const userGrids = useAppSelector((state) => state.mainDashBoard.userGrids);
  const isUserGrids = useAppSelector((state) => state.mainDashBoard.isUserGrids);
  const FilterPanel = useAppSelector((state) => state.mainDashBoard.FilterPanel);
  const lastInsertId = useAppSelector((state) => state.mainDashBoard.lastInsertId);
  const lastInsertGridType = useAppSelector((state) => state.mainDashBoard.lastInsertGridType);
  const dashBoard = useAppSelector((state) => state.dashBoard);
  const notification = useAppSelector((state) => state.notification);
  const stream_last_price_value: any = useAppSelector(stream_last_price);
  const stream_last_isin_value: any = useAppSelector(stream_last_isin);
  const [userAllGrids, setUserAllGrids] = useState([]);
  useEffect(() => {
    if (Number(stream_last_price_value) !== 0) {
      console.log("StreamLAST::", stream_last_price_value);
      console.log("StreamLAST:: stream_last_isin_value", stream_last_isin_value);
      dispatch(
        updatePriceData({
          isin: stream_last_isin_value,
          payload: {
            bp: stream_last_price_value,
            ap: stream_last_price_value,
            bs: 0,
            as: 0,
            cNo: 1,
          },
        })
      );
    }
    //eslint-disable-next-line
  }, [stream_last_price_value, stream_last_isin_value]);
  useEffect(() => {
    if (userGrids) {
      setUserAllGrids(userGrids);
    }
    //eslint-disable-next-line
  }, [userGrids]);
  // console.log("propssMain notification", notification);
  const handleBreakPointChange = (breakpoints: any) => {
    console.log("handleBreakPointChange breakpoints: " + breakpoints);
    dispatch(update_breakpoints(breakpoints));
  };
  useEffect(() => {
    dispatch(getVendorList());
    dispatch(fetchDefaultLoadData());
    dispatch(fetchProfile());
    dispatch(fetchTableHiddenFields());
    // dispatch(fetchProfileCounterparties());
    fetchSettingsData();
    // chartTabDispatch(dashBoard.default_security_set.tabName); //manually setting for the first time only otherwise will be change from holding listing and auto search of order section
    dispatch(fetchNotifications({ page: notification.next_page_index }));
    //eslint-disable-next-line
  }, [dispatch]);

  const gridRef = useRef<any>(null);
  const gridItemRef = useRef<any>(null);
  const handleGridResizeStop = (updateData: any, oldItem: any, newItem: any, layout: any) => {
    let heightInPixels = 0;
    if (gridRef.current) {
      heightInPixels = newItem.h * gridRef.current.props.rowHeight;
      // console.log(`handleGridResizeStop:: Height in pixels: ${heightInPixels}px`);
    }
    let numCols = gridRef.current.props.cols[breakpoints];
    const width = (newItem.w * 100) / numCols;
    dispatch(updateLayoutInUserLayouts({ breakpoints, updateData }));
    // dispatch(storeLayoutToUserLayouts({ layouts }));
    window.removeEventListener("mouseup", handleDragEnd);
  };
  //when user add nee grid layout then after api update lastInsertId then set dependancies
  useEffect(() => {
    if (lastInsertId !== 0) {
      console.log("Last insert id is " + lastInsertGridType);
      if (lastInsertGridType === "chartGrid") {
        const layout = {
          w: 6,
          h: 25,
          x: 0,
          y: 2,
          minW: 3,
          maxW: 12,
          minH: 15,
          i: String(lastInsertId),
        };
        dispatch(addLayoutToUserLayouts({ layout }));
        dispatch(updateISUserLayouts(true));
      } else if (lastInsertGridType === "orderList") {
        const layout = {
          w: 9,
          h: 30,
          x: 0,
          y: 2,
          minW: 5,
          maxW: 12,
          minH: 20,
          i: String(lastInsertId),
        };
        dispatch(addLayoutToUserLayouts({ layout }));
        dispatch(updateISUserLayouts(true));
      } else if (lastInsertGridType === "orderWindow") {
        const layout = {
          w: 2,
          h: 35,
          x: 0,
          y: 33,
          minW: 2,
          maxW: 12,
          minH: 35,
          i: String(lastInsertId),
        };
        dispatch(addLayoutToUserLayouts({ layout }));
        dispatch(updateISUserLayouts(true));
      } else {
        const layout = {
          w: 4,
          h: 20,
          x: 0,
          y: 2,
          minW: 2,
          maxW: 12,
          minH: 10,
          i: String(lastInsertId),
        };
        dispatch(addLayoutToUserLayouts({ layout }));
        dispatch(updateISUserLayouts(true));
      }
    }
  }, [lastInsertId]);
  const [orderFilterShow, setOrderFilterShow] = useState(false);

  useEffect(() => {
    // console.log("FilterPanel :: ", FilterPanel);
    setOrderFilterShow(FilterPanel);
  }, [FilterPanel]);
  const handleLayoutChange = (layout: any) => {
    // console.log("handleLayoutChange:: Layout: ", layouts);
    // let dataAction = "UPDATE_LAYOUT";
    // dispatch(updateLayoutInUserLayouts({ dataAction, layout }));
  };
  const setFilterPanel = () => {
    // console.log("handleLayoutChange:: Layout: ", layouts);
    // let dataAction = "UPDATE_LAYOUT";
    let updatedFilterPanel = FilterPanel ? false : true;
    dispatch(updatesetFilterPanel(updatedFilterPanel));
  };

  // console.log("updateLayoutInUserLayouts:: barLayout: ", layouts);
  useEffect(() => {
    if (is_user_layouts) {
      // console.log("Last insert is user_layouts :: ", is_user_layouts);
      // console.log("Last insert handleLayoutChange:: ", layouts);
      //update database json
      dispatch(storeLayoutToUserLayouts({ layouts }));
      dispatch(updateISUserLayouts(false));
    }
    // console.log("updateLayoutInUserLayouts:: update API: ", layouts);
    // dispatch(updateUserLayoutPosition(layouts));
  }, [layouts]);
  // Concatenate all the inner arrays
  // const alluserGridItems: Array<[]> = [].concat(userGrids.chartGrid, userGrids.orderWindow, userGrids.orderList, userGrids.wishList);
  // console.log("alluserGridItems::  ", userGrids);
  // console.log("girdLayout::  ", girdLayout);
  const orders = useAppSelector((state) => state.orders);
  const rowWidth = [100, 150, 120];
  const sideDrawer = useAppSelector((state) => state.mainDashBoard.SideDrawer);
  const selectedID = useAppSelector((state) => state.mainDashBoard.selectedID);
  const isShowChangeTitle = useAppSelector((state) => state.mainDashBoard.isShowChangeTitle);
  const isShowAddtoWatchList = useAppSelector((state) => state.mainDashBoard.isShowAddtoWatchList);
  const isShowSearchModal = useAppSelector((state) => state.mainDashBoard.isShowSearchModal);
  const isShowmanageColumn = useAppSelector((state) => state.mainDashBoard.isShowmanageColumn);
  const TransactionDetail = useAppSelector((state) => state.orders.transactionData);
  const transactionKillResponse = useAppSelector((state) => state.orders.transactionKillResponse);
  const [isShowChangeTitleModal, SetIsShowChangeTitle] = useState(false);
  const [isShowAddtoList, SetIsShowAddtoList] = useState(false);
  const [isShowSModal, setIsShowSModal] = useState(false);
  const [isShowSColModal, setIsShowSColModal] = useState(false);
  const isShowSecAdd = useAppSelector((state) => state.chartdata.send_new_sec_req);
  const [isShowSecAddModal, setIsShowSecAddModal] = useState(false);
  const [socketDisconnected, setSocketDisconnected] = useState(false);
  const rfq_form = useAppSelector((state) => state.orderSection.rfq_form_data);
  const sendNewSecurityRequestStatus = useAppSelector((state) => state.mainDashBoard.sendNewSecurityRequestStatus);
  useEffect(() => {
    if (sendNewSecurityRequestStatus) {
      toast.success("Request sent successfully.", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      localStorage.removeItem("new_sec_isin");
      dispatch(update_security_message(false));
      dispatch(update_send_new_sec_req(false));
      updatesendNewSecurityRequestStatus(false);
    }
  }, [sendNewSecurityRequestStatus]);
  useEffect(() => {
    setIsShowSModal(isShowSearchModal);
  }, [isShowSearchModal]);
  useEffect(() => {
    setIsShowSColModal(isShowmanageColumn);
  }, [isShowmanageColumn]);

  useEffect(() => {
    // console.log("isShowAddtoWatchList :: ", isShowAddtoWatchList);
    SetIsShowAddtoList(isShowAddtoWatchList);
  }, [isShowAddtoWatchList]);
  useEffect(() => {
    // console.log("isShowChangeTitle :: ", isShowChangeTitle);
    SetIsShowChangeTitle(isShowChangeTitle);
  }, [isShowChangeTitle]);
  useEffect(() => {
    if (transactionKillResponse.length !== 0) {
      // console.log("transactionKillResponse:: IN ", TransactionDetail);
      dispatch(updatesetSideDrawer(false));
      // dispatch(updatesetSelectedID(row.original.id));
    }
  }, [transactionKillResponse]);
  const setupdatesetSideDrawer = () => {
    dispatch(updatesetSideDrawer(true));
  };
  const setcloseSideDrawer = () => {
    dispatch(updatesetSideDrawer(false));
  };
  const updateHoldingChartTypeOption = useAppSelector((state) => state.chartdata.holding_chart_type_option);
  const from_date = useAppSelector((state) => state.chartdata.holding_chart_from_date);
  const to_date = useAppSelector((state) => state.chartdata.holding_chart_to_date);
  const filter_portfolio_ids = useAppSelector((state) => state.dashBoard.portfoliosIds);
  const security_id = useAppSelector((state) => state.dashBoard.default_security_set.security_id);
  const search_currency = useAppSelector((state) => state.dashBoard.default_security_set.iex_symbol);
  const marketId = useAppSelector((state) => state.dashBoard.default_security_set.market_id);
  const mount = useIsMount();
  const payload = useMemo(() => {
    return {
      from_date: from_date,
      to_date: to_date,
      type: Number(marketId) === 6 || Number(marketId) === 2 ? "cash" : "securities",
      security_id:
        updateHoldingChartTypeOption.value === "cash" && (Number(marketId) === 6 || Number(marketId) === 2)
          ? String(security_id)
          : updateHoldingChartTypeOption.value === "securities" && Number(marketId) !== 6 && Number(marketId) !== 2
          ? String(security_id)
          : undefined,
      filter_portfolio_ids: filter_portfolio_ids,
      search_currency: Number(marketId) === 6 || Number(marketId) === 2 ? search_currency.slice(0, 3) : undefined,
      currency_filter: dashBoard?.profile.selected_currency,
    };
  }, [from_date, to_date, security_id, marketId, filter_portfolio_ids, search_currency, dashBoard?.profile.selected_currency]);
  // useEffect(() => {
  //   if (!mount && dashBoard?.profile.selected_currency !== "" && updateHoldingChartTypeOption.value !== "all") {
  //     // console.log("without all selected");
  //     dispatch(postHoldingTypeChartData({ ...payload, type: updateHoldingChartTypeOption.value }));
  //   }
  // }, [payload]);

  // useEffect(() => {
  //   if (!mount && dashBoard?.profile.selected_currency !== "" && updateHoldingChartTypeOption.value === "all") {
  //     // console.log("with all selected");
  //     dispatch(
  //       postHoldingTypeChartData({
  //         from_date: from_date,
  //         to_date: to_date,
  //         type: updateHoldingChartTypeOption.value,
  //         filter_portfolio_ids: filter_portfolio_ids,
  //         search_currency: Number(marketId) === 6 || Number(marketId) === 2 ? search_currency.slice(0, 3) : undefined,
  //         currency_filter: dashBoard?.profile.selected_currency,
  //       })
  //     );
  //   }
  // }, [filter_portfolio_ids, dashBoard?.profile.selected_currency, from_date, updateHoldingChartTypeOption.value]);

  const ltpStreamChannel = "equity_forex_ltp_stream_" + localStorage.getItem("login-id");
  // const rfs_stream_channel = "rfs_stream_channel_" + localStorage.getItem("login-id");
  const rfs_best_stream_channel = "rfs_best_stream_channel_" + localStorage.getItem("login-id");
  const rfs_vendor_stream_channel = "rfs_vendor_stream_channel_" + localStorage.getItem("login-id");
  const equityChartDataChannel = "equity_chart_bidask_data_channel_" + localStorage.getItem("login-id");
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  const [isloggedIn, setIsloggedIn] = useState("internal");
  // useEffect(() => {}, [loggedType]);
  let cNo = 0;

  useEffect(() => {
    socket.on("get_markup_data", (data: any) => {
      console.log("get_markup_data-------------------------------[ok]", data);
      dispatch(update_client_markup_data(data));
    });
    socket.on(ltpStreamChannel, (data: any) => {
      // console.log("LTP Stream data-------------------------------[ok]", data);
      dispatch(update_form_data({ ltp_price_data: data }));
    });
    //START IB stream
    // const channel = localStorage.getItem("stream-channel");
    // const channel =  `ib_price_data_${localStorage.getItem("login-id")}_from_web_split_${socketID}`;;
    const channel = "ib_price_data_" + localStorage.getItem("login-id") + "_from_web";
    socket.on(String(channel), (data: any) => {
      cNo++;
      // set_price_data(data);
      // set_price_data({
      //   [data.sec]: {
      //     bp: Number(data.bp).toFixed(5),
      //     ap: Number(data.ap).toFixed(5),
      //     bs: data.bs,
      //     as: data.as,
      //   },
      // });
      // data.bp = 1.08365;
      // data.ap = 1.08375;
      dispatch(
        updatePriceData({
          isin: data.sec,
          payload: {
            bp: data.bp,
            ap: data.ap,
            bs: data.bs,
            as: data.as,
            cNo: cNo,
          },
        })
      );
      // dispatch(updateBidAskStreamData(data));
      const current_market_id = localStorage.getItem("current_market_id");
      // console.log("IB STRAM :: call here 2", data);
      if (Number(current_market_id) !== 5) {
        if (Number(localStorage.getItem("current_security_conid")) === Number(data.conid)) {
          if (Number(data.bp) !== 0 && Number(data.ap) !== 0) {
            let timeout = 0;
            // if (is_first_call === 0) {
            //   timeout = 0;
            // }
            // setTimeout(() => {
            // set_is_first_call(is_first_call + 1);

            let current_market_id = Number(localStorage.getItem("current_market_id"));
            if (current_market_id === 6) {
              //forex
              let payload = {
                bid_price: Number(data.bp).toFixed(5),
                ask_price: Number(data.ap).toFixed(5),
                rate: Number(data.rate).toFixed(2),
              };

              // console.log("ðŸš€ ~ useEffect ~ updateData:3", payload);
              dispatch(update_prices(payload));

              // if (gridType === "wishList") {
              //   dispatch(updateuserWatchListItem({ payload: data, gridID: gridID }));
              // }
            } else {
              //stocks
              let payload = {
                bid_price: Number(data.bp).toFixed(2),
                ask_price: Number(data.ap).toFixed(2),
                rate: Number(data.rate).toFixed(2),
              };

              // console.log("ðŸš€ ~ useEffect ~ updateData:4", payload);
              dispatch(update_prices(payload));
            }
            // }, timeout);
          } else {
            let cMid: any = localStorage.getItem("current_market_id");
            if (Number(cMid) === 8) {
              dispatch(updateBuyBtnDisabled(true));
              dispatch(updateSellBtnDisabled(true));
            }
          }
        }
      }
    });
    // RFS:: RFS best stream
    socket.on(String(rfs_best_stream_channel), (data: any) => {
      // console.log("RFS_best_stream_channel:: Stream data-------------------------------[ok]", data);
      let send_new_sec = localStorage.getItem("send_new_sec");
      if (send_new_sec === data.sec) {
        let isaskPrice = 0;
        let isBidPrice = 0;
        if (data.typ === "ask") {
          isaskPrice = data.px;
          localStorage.setItem("new_sec_ask", String(data.px));
        }
        if (data.typ === "bid") {
          isBidPrice = data.px;
          localStorage.setItem("new_sec_bid", String(data.px));
        }
        // get price from storage
        let newAskprice = localStorage.getItem("new_sec_ask");
        let newBidprice = localStorage.getItem("new_sec_bid");

        // newAskprice = "56";
        // newBidprice = "55";
        if (Number(newAskprice) === 0 && Number(newBidprice) === 0) {
          //open modal box for requesting new security
          localStorage.setItem("new_sec_isin", String(send_new_sec));
          dispatch(update_security_message(true));
          localStorage.removeItem("new_sec_ask");
          localStorage.removeItem("new_sec_bid");

          localStorage.removeItem("send_new_sec");
        } else {
          // if new security is available then send add security API request as Bond
          dispatch(addNewSecurityRequest({ isin: send_new_sec, mktid: 5 }));
          localStorage.removeItem("new_sec_ask");
          localStorage.removeItem("new_sec_bid");
          localStorage.removeItem("send_new_sec");
          // localStorage.removeItem("send_new_sec", "");
        }
      }
      // dispatch(update_send_new_sec_req(false));
      //START stream update watch list
      if (data.typ === "ask") {
        dispatch(
          updateAskPriceData({
            isin: data.sec,
            payload: {
              ap: Number(data.px) !== 0 ? parseFloat(data.px.toString().slice(0, data.px.toString().indexOf(".") + 4)) : 0,
              as: data.sz,
              at: data.tm,
              vid: data.vid,
            },
          })
        );
      }
      if (data.typ === "bid") {
        dispatch(
          updateBidPriceData({
            isin: data.sec,
            payload: {
              bp: Number(data.px) !== 0 ? parseFloat(data.px.toString().slice(0, data.px.toString().indexOf(".") + 4)) : 0,
              bs: data.sz,
              bt: data.tm,
              vid: data.vid,
            },
          })
        );
      }
      //END stream update watch list
      //START if in order execution window select best execution option then also need to update
      let currentReqvid = localStorage.getItem("current_reqvid_id");
      let current_security_id = localStorage.getItem("current_security_id");
      if (Number(data.reqvid) === Number(currentReqvid) && Number(data.dbsecid) === Number(current_security_id)) {
        dispatch(update_rfs_stream_channel_data(data)); // execution window
      }
      //END
    });
    socket.on("fx_quote_response", (data: any) => {
      console.log("FXQUOTE:: responseFXQuoteOrder", data);
      dispatch(update_fx_rfq_quote(data));
      dispatch(update_form_data({ fx_quote_data: data }));
    });
    // RFS:: RFS vendor wise stream

    socket.on(String(rfs_vendor_stream_channel), (data: any) => {
      console.log("RFS_vendor_stream_channel:: Stream data-------------------------------[ok]", data);

      //START if in order execution window select vendor execution option then also need to update
      let currentReqvid = localStorage.getItem("current_reqvid_id");
      let current_security_id = localStorage.getItem("current_security_id");
      if (Number(data.reqvid) === Number(currentReqvid) && Number(data.dbsecid) === Number(current_security_id)) {
        dispatch(update_rfs_stream_channel_data(data)); // execution window
      }
      //END
      // if client login then watchlist update
      console.log("RFS_vendor_stream_channel:: Stream data------------INNNNNNN-------------------[ok]", loggedType);
      if (loggedType === "external") {
        if (data.typ === "ask") {
          dispatch(
            updateAskPriceData({
              isin: data.sec,
              payload: {
                ap: Number(data.px) !== 0 ? parseFloat(data.px.toString().slice(0, data.px.toString().indexOf(".") + 4)) : 0,
                as: data.sz,
                at: data.tm,
                vid: data.vid,
              },
            })
          );
        }
        if (data.typ === "bid") {
          dispatch(
            updateBidPriceData({
              isin: data.sec,
              payload: {
                bp: Number(data.px) !== 0 ? parseFloat(data.px.toString().slice(0, data.px.toString().indexOf(".") + 4)) : 0,
                bs: data.sz,
                bt: data.tm,
                vid: data.vid,
              },
            })
          );
        }
      }
    });
    socket.on("disconnect", () => {
      const toastId = "disconnect-socket-toast-id";
      if (!toast.isActive(toastId)) {
        toast.warning("Live update connection lost, please wait until auto reconnect or refresh page", {
          toastId,
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setSocketDisconnected(true);
      dispatch(update_is_socket_connected(1));
      console.log("socket:: call disconnect ");
    });
    socket.on("connect_error", () => {
      // dispatch(update_is_socket_connected(1));
      console.log("socket:: call connect_error ");
    });
    socket.on("connect", () => {
      if (socketDisconnected) {
        setSocketDisconnected(false);
        const toastId = "connect-socket-toast-id";
        if (!toast.isActive(toastId)) {
          toast.success("Connection restored", {
            toastId,
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }

      dispatch(update_is_socket_connected(2));
      setTimeout(() => {
        dispatch(update_is_socket_connected(3));
      }, 2000);
      console.log("socket:: call connect --from new repo---");
    });
    socket.on("ibstreamstatus", (data: any) => {
      console.log("IBSTATUS :: ibstreamstatus", data);
    });

    return () => {
      // this now gets called when the component unmounts
      socket.off(String(channel));
      socket.off(String(equityChartDataChannel));
      socket.off("security_subscription");
      socket.off("ibstreamstatus");
      // socket.off("tradeweb-quotes");
    };
  }, [socket, rfq_form.rfq_request_id, loggedType, socketDisconnected]);
  socket.on("tradeweb-quotes", (data: any) => {
    console.log("RFQ:: 1-3 Stream data-------------------------------[ok]", data);
    dispatch(
      update_tradeweb_quotes_stream_channel_data({
        px: data.px,
        qid: data.qid,
        rid: data.rid,
        sec: data.sec,
        sz: data.sz,
        tm: data.tm,
        typ: data.typ,
        vid: data.vid,
      })
    );
  });
  socket.on("future-options-security-response", (data: any) => {
    //var repo = '[{"Exchange": "CBOE","TradingClass": "AAPL","Multiplier": "100","Expirations": "20250307","Strikes": 5,"DisplaySecurity": "CBOE|2025-03-07|5|CALL"},{"Exchange": "CBOE","TradingClass": "AAPL","Multiplier": "100","Expirations": "20250307","Strikes": 5,"DisplaySecurity": "CBOE|2025-03-07|5|PUT"},{"Exchange": "CBOE","TradingClass": "AAPL","Multiplier": "100","Expirations": "20250307","Strikes": 10,"DisplaySecurity": "CBOE|2025-03-07|10|CALL"},{"Exchange": "CBOE","TradingClass": "AAPL","Multiplier": "100","Expirations": "20250307","Strikes": 10,"DisplaySecurity": "CBOE|2025-03-07|10|PUT"}]';
    console.log("FUTURE|OPTIONS:: 1-3 Stream data-------------------------------[ok]", data, data?.Value?.msg);
    if(data?.Key === "ERROR"){
      console.log(data.msg)
      dispatch(setFUTOPTErrorMsg(data?.Value?.msg))
      dispatch(setFUTOPTData({
        Key: "",
        Value: {
          cid: "",
          exc: "",
          futlst: [],
          optlst: [],
          rid: "",
          sec: "",
          sectype: "",
          vid: 0,
        },
      },))
    }else{
      dispatch(setFUTOPTErrorMsg(""))
      dispatch(setFUTOPTData(data));
    }
  });
  // const handleDragStart = useCallback((layout, oldItem, newItem, placeholder, e): any => {
  //   window.addEventListener("mouseup", handleDragEnd);
  //   // rest of the code
  // }, []);
  useEffect(() => {}, []);

  const handleDragStart = (updateData: any) => {};
  const handleGridDragStop = (updateData: any) => {
    dispatch(updateLayoutInUserLayouts({ breakpoints, updateData }));
    window.removeEventListener("mouseup", handleDragEnd);
    // dispatch(storeLayoutToUserLayouts({ layouts }));
  };
  const handleDragEnd = useCallback(() => {
    // window.removeEventListener("mouseup", handleLayoutChange);
  }, []);
  const is_screen_lock = useAppSelector((state) => state.mainDashBoard.is_screen_lock);

  const [zIndex, setZindex] = useState<boolean>(false);

  const handleZindex = () => {
    setZindex((prev) => !prev);
  };
  // const menuData = useAppSelector((state) => state.dashBoard.menuData);
  return (
    <>
      {isUserGrids && (
        <>
          <div className="justify-between mt-[20px]  px-[15px]   tab-group">
            <Menubar />
          </div>

          <div className="grid-layout-panel">
            <ul className="grid-layout-panel-list"></ul>
          </div>

          <ResponsiveGridLayout
            // {...props}
            // isDroppable={true}
            // onDragStart={(a, b, c, d, e) => e.stopPropagation()}
            ref={gridRef}
            className="layout"
            layouts={layouts && layouts}
            onBreakpointChange={handleBreakPointChange}
            // onLayoutChange={handleLayoutChange}
            onDragStop={handleGridDragStop}
            onResizeStop={handleGridResizeStop}
            isDraggable={!is_screen_lock}
            // isRearrangeable
            isResizable={!is_screen_lock}
            rowHeight={1}
            // width={1920}
            // onWidthChange={handleWidthChange}
            // onDragStart={handleDragStart}
            // onDragStop={handleDragEnd}
            draggableHandle=".grid-item__title"
            breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          >
            {userGrids.map((item: any, index: number) => (
              <div
                key={item.id}
                className={`grid-item-container ${item.grid_component_slug === "orderWindow" && zIndex === true && " zIndex1Negative"} `}
              >
                {/* {item.grid_component_slug} */}
                <GridItemContainer
                  handleZindex={handleZindex}
                  zIndex={zIndex}
                  index={index}
                  key={item.id}
                  title={item.title}
                  gridType={item.grid_component_slug}
                  item={item}
                  {...props}
                />
              </div>
            ))}
          </ResponsiveGridLayout>

          <PopModal show={orderFilterShow} setShow={setOrderFilterShow}>
            <Filterpanel dashBoard={dashBoard} transaction={orders} setShow={setOrderFilterShow} />
          </PopModal>
          <SideDrawer sideDrawer={sideDrawer} setSideDrawer={setupdatesetSideDrawer} details={TransactionDetail}>
            {orders.transactionDetailStatus === "loading" ? (
              <Loading isMaximized="RecordListing" />
            ) : (
              <TransactionDetails details={TransactionDetail} selectedorderid={selectedID} setClose={setcloseSideDrawer} />
            )}
          </SideDrawer>
          <PopModal
            show={isShowChangeTitle}
            setShow={SetIsShowChangeTitle}
            classString="w-[20vw] min-h-[20vh] max-h-[95vh] overflow-auto  bg-layoutBg  rounded-[10px]"
          >
            <Wishlist setShow={SetIsShowChangeTitle} />
          </PopModal>
          <PopModal
            show={isShowAddtoWatchList}
            setShow={SetIsShowAddtoList}
            classString="w-[20vw] min-h-[20vh] max-h-[95vh] overflow-auto  bg-layoutBg  rounded-[10px]"
          >
            <AddToWatchList setShow={SetIsShowAddtoList} />
          </PopModal>
          <PopModal show={isShowSearchModal} setShow={setIsShowSModal} classString="watch-list-searchbar bg-layoutBg  rounded-[10px]">
            <SearchAddToWatchList setShow={setIsShowSModal} />
          </PopModal>
          <PopModal show={isShowmanageColumn} setShow={setIsShowSColModal} classString="order-col-list bg-layoutBg  rounded-[10px]">
            <ManageOrderColumn setShow={setIsShowSColModal} />
          </PopModal>
          <PopModal show={isShowSecAdd} setShow={setIsShowSecAddModal} classString="security-add bg-layoutBg  rounded-[10px]">
            <SecurityAddRequest setShow={setIsShowSecAddModal} isincode={localStorage.getItem("send_new_sec")} />
          </PopModal>

          {/* <button onClick={handleClick}>Add</button> */}
        </>
      )}
    </>
  );
}
export default MainDashboard;
