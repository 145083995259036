import React, { useState } from "react";
import Modal from "../Modal/Modal";
import classNames from "classnames";
import FutureForm from "./FutureForm/FutureForm";
import OptionsForm from "./OptionForm/Optionform";
import { contracts, exchanges } from "../../utils/staticData";
import { requestFutOpt, requestRfq } from "../../Redux/features/OrderSection/OrderSectionSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { loaderStart } from "../../Redux/features/Dashboard/DashBoardSlice";
import Loading from "../Loading/Loading";
type Props = {
  conValue: string;
  setConValue: any;
  contractSet: string;
  setContractSet: any;
};

const FutAndOpt = ({ conValue, setConValue, contractSet, setContractSet }: Props) => {
  const [isFutureModalOpen, setFutureModalOpen] = useState(false);
  const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);

  const default_security_set: any = useAppSelector((state) => state.dashBoard.default_security_set);

  const dispatch = useAppDispatch();
  const handleRequest = (type: "FUT" | "OPT") => {
    dispatch(requestFutOpt({ security_id: default_security_set.security_id, type: type }));
    dispatch(loaderStart());
  };
  const data = useAppSelector((state) => state.dashBoard.futoptData);
  const loader = useAppSelector((state) => state.dashBoard.fut_opt_loader);
  // const loader = false;
  console.log(default_security_set, "default_security_set");
  return (
    <>
      <div className="flex cursor-pointer bg-[#f0f1f3] items-center m-auto shadow px-[4px] py-[2px] rounded-md text-[#6e6e6e] gap-2">
        <div
          onClick={() => {
            setFutureModalOpen(true);
            setContractSet("future");
            handleRequest("FUT");
            setConValue(null);
          }}
          className={classNames("px-[4px] text-[10px] m-auto py-[2px] rounded", {
            "bg-white": contractSet === "future",
          })}
        >
          FUT
        </div>
        <div
          onClick={() => {
            setIsOptionModalOpen(true);
            setContractSet("option");
            handleRequest("OPT");
            setConValue(null);
          }}
          className={classNames("px-[4px] text-[10px] py-[2px] rounded", {
            "bg-white": contractSet === "option",
          })}
        >
          OPT
        </div>
      </div>

      <Modal text={default_security_set.tabName} isOpen={isFutureModalOpen} onClose={() => {setFutureModalOpen(false); setContractSet("")}}>
        {!loader ? (
          <FutureForm contracts={data?.Value?.futlst} setConValue={setConValue} onClose={() => setFutureModalOpen(false)} />
        ) : (
          <>
            <Loading />
          </>
        )}
      </Modal>
      <Modal text={default_security_set.tabName} isOpen={isOptionModalOpen} onClose={() => {setIsOptionModalOpen(false); setContractSet("")}}>
        {!loader ? (
          <OptionsForm exchanges={data?.Value?.optlst} setConValue={setConValue} onClose={() => setIsOptionModalOpen(false)} />
        ) : (
          <>
            <Loading />
          </>
        )}
      </Modal>
    </>
  );
};

export default FutAndOpt;
